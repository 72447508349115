import React from 'react'
import Head from 'next/head'
import { Query } from '@apollo/client/react/components'
import get from 'lodash/get'
import dynamic from 'next/dynamic'
import truncate from 'lodash/truncate'
import stripHtml from 'string-strip-html'
import { withNamespaces } from '>/i18n'

import withLayout from '~/hoc/withLayout'
import withFestivalContext from '~/hoc/withFestivalContext'
import { getFestivalRangeShort } from '~/utils/helpers'

import { FESTIVAL_EDITION_STATES, DESCRIPTION_LIMIT } from '~/utils/consts'
import { GET_CURRENT_FESTIVAL_EDITION } from '~/utils/queries.gql'

import Modal from '~/components/Modal'
import { commonModals } from '~/components/Modal/modalsList'
import config from '~/config'

const Festival = dynamic(() => import('~/components/Festival/rw/index'))

const HeadTags = ({ festival }) => {
  const { festivalName, t, state } = festival

  const description = state === FESTIVAL_EDITION_STATES.VOUCHER ?
    t(`app.${festivalName}.description_inactive`) :
    t(`app.${festivalName}.description`, { range: getFestivalRangeShort(festival) })

  const formattedName = `${t(`app.${festivalName}.title`)}`
  const formattedDescription = truncate(stripHtml(description).result, {
    'length': DESCRIPTION_LIMIT,
    'separator': ' '
  })
  const image = `${config().clubUrl}/static/assets/festival/${festivalName}/share.png` || undefined

  return (
    <Head>
      <title>{formattedName}</title>
      <meta name='description' content={formattedDescription} />
      <meta property='og:title' content={formattedName} key='title' />
      <meta property='og:description' content={formattedDescription} key='description' />
      {image && <meta property='og:image' content={image} key='image' />}
    </Head>
  )
}

export default
@withLayout({
  namespaces: ['tooltips', 'modal'],
  contentClassName: 'festival'
})
@withFestivalContext
@withNamespaces(['meta', 'homepage-rw'])
class Index extends React.Component {
  render () {
    const { festivalName, t } = this.props
    const festival = config(festivalName).festival

    return (
      <>
        <Query
          query={GET_CURRENT_FESTIVAL_EDITION}
          variables={{ code: festivalName }}
        >
          {({ data }) => {
            const isVoucher = get(data, 'currentFestivalEdition.state') === FESTIVAL_EDITION_STATES.VOUCHER

            return (
              <>
                <HeadTags festival={{ festivalName, t, ...get(data, 'currentFestivalEdition') }} />
                <Festival isVoucher={isVoucher} />
                {config().chatbotHash && festival.chatbotEnabled && <script id='chatbot' async type='text/javascript' src={`https://cdn.thulium.com/apps/chat-widget/chat-loader.js?hash=${config().chatbotHash}`} />}
              </>
            )
          }}
        </Query>
        <Modal components={commonModals} />
      </>
    )
  }
}
